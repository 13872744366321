<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useStore } from "vuex";
import AppLink from '@/components/AppLink.vue';
import IconSearch from '@/components/icons/IconSearch.vue';
import IconBurger from '@/components/icons/IconBurger.vue';
import dog from '@/assets/images/catalog-dog-2x.png';
import cat from '@/assets/images/catalog-cat-2x.png';
import catalog from '@/assets/images/catalog-img-2x.png';
import HeaderSearch from '@/components/HeaderSearch.vue';
import { useRoute } from 'vue-router';
import AppHeaderLogo from '@/components/images/AppHeaderLogo.vue';
import AppModal from "@/components/AppModal.vue";
import AppLocation from "@/components/AppLocation.vue";

const mediaQuery = '(max-width: 1024px)';
const isMobile = window.matchMedia(mediaQuery).matches;
// const isMobile = computed(() => { window.matchMedia(mediaQuery).matches });
const route = useRoute();
const store = useStore();

const isMobileMenuActive = ref<boolean>(false);
const scrollPrev = ref<number>(window.scrollY);
const isHeaderHide = ref<boolean>(false);
const isCatalogShow = ref<boolean>(false);
const isSearchBarShow = ref<boolean>(false);

const modalLocation = ref(null);
// let modalLocation = store.state.modal.location;
// const modalLocation = ref(store.state.modal.location);

// console.log( store.state.modal.location );

const slides = computed(() => store.state?.options?.promo?.banner_header);
// const location = computed(() => store.state.location);
const location = computed(() => store.getters.location);


watch(isHeaderHide, (newVal) => {
    if (newVal && isCatalogShow.value) displayCatalog();
    if (newVal && isSearchBarShow.value) displaySearchBar();
});

scrollHandler();
window.addEventListener('scroll', scrollHandler);

function setIsMobileMenuActive() {
    if (isCatalogShow.value && isMobileMenuActive.value) displayCatalog();
    isMobileMenuActive.value = !isMobileMenuActive.value;
}

function scrollHandler() {
    if (isMobile) return;
    let scrolled = window.scrollY;
    isHeaderHide.value = scrolled > 100 && scrolled > scrollPrev.value;
    scrollPrev.value = scrolled;
}

const headerClass = computed((): string => {
    if (isMobile) {
        if (isMobileMenuActive.value) return 'translate-x-0';

        return 'lg:translate-x-0 translate-x-[350px]';
    }

    return '';
});

function displayCatalog() {
    isCatalogShow.value = !isCatalogShow.value;
}

function displaySearchBar() {
    isSearchBarShow.value = !isSearchBarShow.value;
}

function hideSearchBarAndCatalog() {
    isSearchBarShow.value = false;
    isCatalogShow.value = false;
    isMobileMenuActive.value = false;
}

function clearPhoneNumber(number: string): string {
    return number.replace(/\D/g, '');
}

function hideSearchBarAndCatalogByOutsideClick(e: Event) {
    if (isMobile) return;

    const target = e.target as HTMLElement;
    const isTargetBelongsHeader = target.closest('header.app-header');

    if (!isTargetBelongsHeader) {
        hideSearchBarAndCatalog();
    }
}

watch(route, hideSearchBarAndCatalog);

document.addEventListener('click', hideSearchBarAndCatalogByOutsideClick);

</script>

<template>

    <IconBurger :is-active="isMobileMenuActive" @click="setIsMobileMenuActive" class="z-[3200]" v-if="isMobile" />
    <div
        class="fixed z-[3000] top-0 left-0 bottom-0 right-0 transition-all duration-300"
        :class="isMobileMenuActive ? 'bg-black/50' : 'bg-black/0'"
        v-if="isMobile && isMobileMenuActive"
        @click="setIsMobileMenuActive"
    />
    <RouterLink to="/" class="lg:hidden block ml-6 mt-4">
        <AppHeaderLogo class="h-auto w-[168px]" />
    </RouterLink>
    <header
        class="lg:pl-5 lg:pr-5 pl-12 fixed top-0 lg:left-0 left-auto lg:right-auto right-0 lg:w-full w-[295px] lg:bottom-auto bottom-0 z-[3100] bg-white transition-all duration-300 app-header"
        :class="`${headerClass} ${!isMobile && isHeaderHide ? '-translate-y-20' : 'translate-y-0'}`"
    >
        <div
            class="lg:max-w-container max-w-[156px] lg:mx-auto flex lg:flex-row flex-col lg:h-auto h-full w-full lg:justify-between justify-center lg:items-center xl:gap-x-9.5 gap-x-5 gap-y-11.5 xl:pr-7 bg-white relative z-20 lg:py-4.5 py-8"
        >
            <RouterLink
                to="/"
                class="group grow-0 shrink-0"
            >
                <!-- :class="{
                    'opacity-0': scrollPrev < 450 && $route.name === 'home',
                }" -->
                <AppHeaderLogo class="h-auto xl:w-[120px] lg:w-25 w-[134px] block" />
            </RouterLink>
            <div
                class="flex lg:flex-row flex-col lg:justify-end justify-start lg:items-center hg:gap-x-19 2xl:gap-x-10 xl:gap-x-8 gap-5 grow"
            >
                <button
                    class="border flex justify-center items-center xl:py-1.5 lg:py-1 py-1.5 xl:px-7.5 px-4 xl:gap-x-5 lg:gap-x-2 gap-x-5 rounded-60 cursor-pointer shrink-0 grow-0 transition-all duration-300"
                    :class="{
                        'border-turquoise bg-turquoise': !isCatalogShow,
                        'border-blue bg-white': isCatalogShow,
                    }"
                    @click="displayCatalog"
                >
                    <span class="">Каталог</span>
                    <svg
                        width="15"
                        height="8"
                        class="xl:w-auto h-auto lg:w-2.5 w-auto transition-all duration-500"
                        :style="{
                            transform: `rotate(${isCatalogShow ? 180 : 0}deg)`,
                        }"
                        viewBox="0 0 15 8"
                        fill="none"
                    >
                        <path
                            d="M0.500061 0.5L7.50006 7.5L14.5 0.5"
                            class="stroke-blue"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
                <div
                    class="flex lg:flex-row flex-col w-fit lg:items-center justify-end hg:gap-x-15 2xl:gap-x-10 xl:gap-x-8 gap-x-5 gap-y-12 lg:grow"
                >
                    <div
                        class="lg:items-center w-fit flex lg:flex-row flex-col gap-x-6 xl:gap-x-12 gap-y-5 shrink-0 lg:grow justify-center"
                    >
                        <AppLink text="О бренде" to="/about" />
                        <AppLink text="Линейки" to="/product-line" />
                        <AppLink text="Новости" to="/news" />
                        <!-- <AppLink text="Партнерам" to="/for-partners" />-->
                        <AppLink
                            text="#Obritитeдруга"
                            target="_blank"
                            to="https://obrititedruga.brit-rus.ru"
                            rel="noopener noreferrer"
                            :is-route="false"
                        />
                        <AppLink
                            text="Сообщество BritVet"
                            target="_blank"
                            to="https://brit-vet.ru/ "
                            rel="noopener noreferrer"
                            :is-route="false"
                        />
                        <!-- <AppLink text="Амбассадоры" to="/ambassadors" />-->
                    </div>
                    <div
                        class="flex lg:flex-row flex-col lg:items-center justify-end hg:gap-x-8.5 gap-x-5 gap-y-8.5"
                    >
                        <div
                            class="flex lg:flex-row flex-col lg:items-center lg:justify-end justify-center hg:gap-x-7 gap-x-3 w-full grow gap-y-5"
                        >
                            <div class="relative">
                                <router-link
                                    to="/where-to-buy"
                                    class="bg-turquoise xl:py-1.5 lg:py-1 py-1.5 2xl:px-10 xl:px-6 px-5 rounded-60 cursor-pointer shrink-0 grow-0 border border-turquoise transition-all duration-300 hover:bg-white hover:border-blue lg:mb-0 mb-12 whitespace-nowrap"
                                >
                                    Где купить
                                </router-link>
                                <HeaderSearch
                                    :is-search-bar-show="isSearchBarShow"
                                    @display-search-bar="hideSearchBarAndCatalog"
                                />
                            </div>
                            <IconSearch
                                class="cursor-pointer shrink-0 grow-0 lg:block hidden"
                                @click="displaySearchBar"
                            />
                            <button class="flex items-center gap-x-2 cursor-pointer" @click="modalLocation.show()">
                                <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.91698 10.9661C4.29525 11.3189 4.66393 11.6627 5.00319 12C5.34227 11.6623 5.71078 11.318 6.08887 10.9649C8.36885 8.83505 10.997 6.37997 9.61802 3.06837C8.8503 1.22476 6.99454 0 5.00007 0C3.00561 0 1.14984 1.22476 0.382121 3.06837C-0.998037 6.38269 1.63402 8.83719 3.91698 10.9661ZM5 7C6.10457 7 7 6.10457 7 5C7 3.89543 6.10457 3 5 3C3.89543 3 3 3.89543 3 5C3 6.10457 3.89543 7 5 7Z" fill="#161345"/>
                                </svg>
                                <span class="whitespace-nowrap xl:block lg:hidden" v-html="location"></span>
                            </button>

                            <!-- TODO: add mobile search -->

                            <form
                                method="get"
                                action="/search"
                                class="flex lg:hidden items-center w-full border border-blue rounded-full overflow-hidden py-1.5 px-3 gap-x-2.5"
                            >
                                <button type="submit">
                                    <IconSearch class="w-4 h-auto" />
                                </button>
                                <input
                                    name="q"
                                    type="text"
                                    placeholder="Поиск"
                                    class="w-full block bg-white p-0 focus:outline-none"
                                />
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="absolute bg-white lg:left-0 md:left-auto -left-[calc(100vw_-_295px)] lg:w-full md:w-[calc(98vw_-_295px)] w-auto lg:top-full top-0 lg:right-auto md:right-[295px] right-0 lg:bottom-auto md:bottom-0 md:h-auto h-full md:overflow-y-visible overflow-y-auto lg:rounded-b-20 py-10 px-5 md:z-10 z-30 transition-all duration-500"
            :style="{
                transform: `translateY(${isCatalogShow ? '0' : '-300vw'})`,
            }"
        >
            <div class="md:hidden block mb-9.5">
                <AppHeaderLogo class="w-[168px] h-auto mx-auto mb-6.5" />
                <button
                    class="rounded-full flex items-center justify-center py-2.5 px-5.5 bg-[#F1F1F1] gap-x-2.5 w-fit mx-auto"
                    @click="displayCatalog"
                >
                    <span>Каталог</span>
                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none">
                        <line
                            y1="-1"
                            x2="12.5508"
                            y2="-1"
                            transform="matrix(-0.698142 -0.715959 0.698143 -0.715959 10.5 8.98584)"
                            stroke="#161345"
                            stroke-width="2"
                        />
                        <line
                            y1="-1"
                            x2="12.5508"
                            y2="-1"
                            transform="matrix(-0.698143 0.715959 -0.698142 -0.715959 9.26172 0.0141602)"
                            stroke="#161345"
                            stroke-width="2"
                        />
                    </svg>
                </button>
            </div>
            <div class="lg:max-w-container mx-auto flex items-stretch xl:gap-x-[143px] gap-x-10">
                <div class="max-w-[1030px] w-full">
                    <div
                        class="grid lg:grid-cols-3 grid-cols-2 gap-y-10 gap-x-5 border-b border-blue pb-20"
                    >
                        <div class="">
                            <router-link to="/catalog/animal-is-dogs" class="inline-block">
                                <img
                                    :src="dog"
                                    alt="img"
                                    width="60"
                                    height="60"
                                    class="lg:mb-7.5 mb-5 rounded-full object-cover object-center"
                                />
                            </router-link>
                            <p class="lg:mb-7.5 mb-5 font-gill lg:text-30 text-24 leading-none">
                                <router-link to="/catalog/animal-is-dogs">
                                    Собакам
                                </router-link>
                            </p>
                            <ul class="grid lg:gap-y-5 gap-y-2.5">
                                <li>
                                    <AppLink
                                        text="Сухой корм"
                                        to="/catalog/korma-suhie/animal-is-dogs"
                                    />
                                </li>
                                <li>
                                    <AppLink
                                        text="Влажный корм"
                                        to="/catalog/korma-vlajnye/animal-is-dogs"
                                    />
                                </li>
                                <li>
                                    <AppLink
                                        text="Паучи"
                                        to="/catalog/korma-vlajnye/type-is-pauchi/animal-is-dogs"
                                    />
                                </li>
                                <li>
                                    <AppLink
                                        text="Паштеты"
                                        to="/catalog/korma-vlajnye/type-is-pashtety/animal-is-dogs"
                                    />
                                </li>
                                <li>
                                    <AppLink
                                        text="Консервы"
                                        to="/catalog/korma-vlajnye/type-is-konservy/animal-is-dogs"
                                    />
                                </li>
                            </ul>
                        </div>
                        <div class="">
                            <router-link to="/catalog/animal-is-cats" class="inline-block">
                                <img
                                    :src="cat"
                                    alt="img"
                                    width="60"
                                    height="60"
                                    class="lg:mb-7.5 mb-5 rounded-full object-cover object-center"
                                />
                            </router-link>
                            <p class="lg:mb-7.5 mb-5 font-gill lg:text-30 text-24 leading-none">
                                <router-link to="/catalog/animal-is-cats">
                                    Кошкам
                                </router-link>
                            </p>
                            <ul class="grid lg:gap-y-5 gap-y-2.5">
                                <li>
                                    <AppLink
                                        text="Сухой корм"
                                        to="/catalog/korma-suhie/animal-is-cats"
                                    />
                                </li>
                                <li>
                                    <AppLink
                                        text="Влажный корм"
                                        to="/catalog/korma-vlajnye/animal-is-cats"
                                    />
                                </li>
                                <li>
                                    <AppLink
                                        text="Паучи"
                                        to="/catalog/korma-vlajnye/type-is-pauchi/animal-is-cats"
                                    />
                                </li>
                                <li>
                                    <AppLink
                                        text="Паштеты"
                                        to="/catalog/korma-vlajnye/type-is-pashtety/animal-is-cats"
                                    />
                                </li>
                                <li>
                                    <AppLink
                                        text="Консервы"
                                        to="/catalog/korma-vlajnye/type-is-konservy/animal-is-cats"
                                    />
                                </li>
                            </ul>
                        </div>

                        <!--                        <div class="lg:col-span-1 col-span-full">-->
                        <!--                            <img-->
                        <!--                                :src="suggest"-->
                        <!--                                alt="img"-->
                        <!--                                width="60"-->
                        <!--                                height="60"-->
                        <!--                                class="lg:mb-7.5 mb-5 rounded-full object-cover object-center"-->
                        <!--                            />-->
                        <!--                            <p class="lg:mb-7.5 mb-5 font-gill lg:text-30 text-24 leading-none">-->
                        <!--                                Подборки-->
                        <!--                            </p>-->
                        <!--                            <ul class="grid lg:gap-y-5 gap-y-2.5">-->
                        <!--                                <li>-->
                        <!--                                    <AppLink text="Для стерилизованных кошек" to="#" />-->
                        <!--                                </li>-->
                        <!--                                <li>-->
                        <!--                                    <AppLink-->
                        <!--                                        text="Для кошек с чувствительным пищеварением"-->
                        <!--                                        to="#"-->
                        <!--                                    />-->
                        <!--                                </li>-->
                        <!--                                <li>-->
                        <!--                                    <AppLink text="Для котят" to="#" />-->
                        <!--                                </li>-->
                        <!--                                <li>-->
                        <!--                                    <AppLink-->
                        <!--                                        text="Для собак мини пород с чувствительным пищеварением"-->
                        <!--                                        to="#"-->
                        <!--                                    />-->
                        <!--                                </li>-->
                        <!--                                <li>-->
                        <!--                                    <AppLink text="Для щенков мини пород" to="#" />-->
                        <!--                                </li>-->
                        <!--                            </ul>-->
                        <!--                        </div>-->

                    </div>
                    <div class="pt-10 flex items-center lg:gap-x-25 gap-x-5">
                        <router-link to="/catalog" class="flex items-center lg:gap-x-5.5 gap-x-3">
                            <span class="font-gill lg:text-24 text-20 leading-none">Каталог</span>
                            <svg width="9" height="16" viewBox="0 0 9 16" fill="none">
                                <path
                                    d="M1 15L8 8L1 1.00009"
                                    stroke="#161345"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </router-link>
                        <router-link to="/brit-care" class="flex items-center lg:gap-x-5.5 gap-x-3">
                            <span class="font-gill lg:text-24 text-20 leading-none">Brit Care</span>
                            <svg width="9" height="16" viewBox="0 0 9 16" fill="none">
                                <path
                                    d="M1 15L8 8L1 1.00009"
                                    stroke="#161345"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </router-link>
                        <router-link
                            to="/brit-premium"
                            class="flex items-center lg:gap-x-5.5 gap-x-3"
                        >
                            <span class="font-gill lg:text-24 text-20 leading-none">
                                Brit Premium
                            </span>
                            <svg width="9" height="16" viewBox="0 0 9 16" fill="none">
                                <path
                                    d="M1 15L8 8L1 1.00009"
                                    stroke="#161345"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </router-link>
                    </div>
                </div>

                <div v-if="slides?.length" class="relative h-full shrink-0 grow-0 lg:block hidden lg:basis-[330px]">
                    <template v-for="(slide, index) in slides">
                        <RouterLink
                            v-if="slide.url"
                            :to="slide.url"
                            class="absolute w-full h-full z-20 top-0 left-0"
                        />
                        <img
                            :src="slide.images[0]"
                            alt="img"
                            width="330"
                            class="h-full object-center object-cover rounded-20 relative z-10 xl:w-auto w-[300px]"
                        />
                    </template>
                </div>

            </div>
        </div>
    </header>

    <app-modal ref="modalLocation" class="max-w-md">
        <app-location @set-location="modalLocation.hide()"></app-location>
    </app-modal>

</template>
