﻿<script setup lang="ts">
import SectionTop from '@/components/home-page/SixSection/SectionTop.vue';
import SectionBottom from '@/components/home-page/SixSection/SectionBottom.vue';
import bg from '@/assets/images/backgroundHome.png';
import feedPackCat from '@/assets/images/catClose.png';
import feedPack from '@/assets/images/brit__dog.png';
import IconSixSection1 from '@/components/icons/IconSixSection1.vue';
import IconSixSection2 from '@/components/icons/IconSixSection2.vue';
import IconSixSection3 from '@/components/icons/IconSixSection3.vue';
import IconSixSection4 from '@/components/icons/IconSixSection4.vue';
import IconSixSection5 from '@/components/icons/IconSixSection5.vue';
import IconSixSection6 from '@/components/icons/IconSixSection6.vue';
import IconSixSectionCat1 from '@/components/icons/IconSixSectionCat1.vue';
import IconSixSectionCat2 from '@/components/icons/IconSixSectionCat2.vue';
import IconSixSectionCat3 from '@/components/icons/IconSixSectionCat3.vue';
import IconSixSectionCat4 from '@/components/icons/IconSixSectionCat4.vue';
import IconSixSectionCat5 from '@/components/icons/IconSixSectionCat5.vue';
import IconSixSectionCat6 from '@/components/icons/IconSixSectionCat6.vue';


import AOS from 'aos';
import 'aos/dist/aos.css';
import { ref } from 'vue';

const desktopWidth = ref(true);

if (window.matchMedia('(max-width: 1024px)').matches) {
    desktopWidth.value = false;
} else {
    desktopWidth.value = true;
}

AOS.init();
</script>

<template>
    <section v-if="desktopWidth" class="overflow-hidden bg-turquoise-200 relative z-20">
        <SectionTop />
        <SectionBottom />
    </section>
    <section v-if="!desktopWidth" class="bg-turquoise-200 relative z-20">
      <div class="w-full max-w-[768px] relative aspect-square bg-no-repeat bg-contain bg-center py-10 mx-auto" :style="`background-image: url(${bg});`">
        <img :src='feedPack' alt="" class="packPrem max-w-[45%] mx-auto absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%]">
        <div data-aos="rotate" data-aos-duration="1000" data-aos-delay="100" data-aos-offset="500" class="relative w-full aspect-square">
          <IconSixSection1 class="icon absolute top-1/2 translate-y-[50%] right-[12.5%] translate-x-[50%]"/>
          <IconSixSection2 class="icon absolute left-[50%] -translate-x-[50%] top-0 z-20"/>
          <IconSixSection3 class="icon absolute left-[12.5%] -translate-x-[50%] top-1/2 translate-y-[50%] z-20"/>
          <IconSixSection4 class="icon absolute bottom-1/2 -translate-y-[50%] right-[12.5%] translate-x-[50%]"/>
          <IconSixSection5 class="icon absolute bottom-0 right-1/2 translate-x-[50%]"/>
          <IconSixSection6 class="icon absolute bottom-1/2 -translate-y-[50%] left-[12.5%] -translate-x-[50%]"/>
        </div>
      </div>
      <div class="w-full max-w-[768px] relative aspect-square bg-no-repeat bg-contain bg-center py-10" :style="`background-image: url(${bg});`">
        <img :src='feedPackCat' alt="" class="max-w-[55%] mx-auto absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%]">
        <div data-aos="rotate-inverse" data-aos-duration="1000" data-aos-delay="100" data-aos-offset="500" class="w-full  w-full aspect-square">
          <IconSixSectionCat1 class="icon absolute top-1/2 translate-y-[50%] right-[12.5%] translate-x-[50%]"/>
          <IconSixSectionCat2 class="icon absolute left-[50%] -translate-x-[50%] top-0 z-20"/>
          <IconSixSectionCat3 class="icon absolute left-[12.5%] -translate-x-[50%] top-1/2 translate-y-[50%] z-20"/>
          <IconSixSectionCat4 class="icon absolute bottom-1/2 -translate-y-[50%] right-[12.5%] translate-x-[50%]"/>
          <IconSixSectionCat5 class="icon absolute bottom-0 right-1/2 translate-x-[50%]"/>
          <IconSixSectionCat6 class="icon absolute bottom-1/2 -translate-y-[50%] left-[12.5%] -translate-x-[50%]"/>
        </div>
      </div>
    </section>
</template>

<style scoped lang="scss">
.packPrem {
  
}
.icon {
  width: 8rem;
  height: 8rem;
  @media (width <= 680px) {
    width: 7rem;
    height: 7rem;
  }
  @media (width <= 450px) {
    width: 5rem;
    height: 5rem;
  }
}
[data-aos="rotate"] {
  transform: rotate(360deg);
  opacity: 1;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: rotate(0);
    opacity: 1;
  }
}
[data-aos="rotate-inverse"] {
  transform: rotate(-360deg);
  opacity: 1;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: rotate(0);
    opacity: 1;
  }
}
</style>