﻿<script setup lang="ts">
import { ref, watch } from 'vue';

const isShow = ref(false);

function show() {
    isShow.value = true;
    document.body.style.overflow = 'hidden';
}

function hide() {
    isShow.value = false;
    document.body.style.overflow = '';
}

defineExpose({
    isShow,
    show,
    hide,
});

defineProps({
    class: {
        type: String,
        default: ''
    }
});
</script>

<template>
    <transition name="fade">
        <div v-if="isShow" class="modal">
            <div class="modal-overlay">
                <div class="modal-backdoor" @click="hide"></div>
                <div class="modal-content" :class="class">
                    <button class="modal-close" @click="hide">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <g clip-path="url(#clip0_6_9024)">
                                <path
                                    d="M17.1834 15.0208L29.513 2.7586C29.6534 2.62048 29.7649 2.4558 29.841 2.27415C29.9171 2.0925 29.9563 1.89752 29.9563 1.70057C29.9563 1.50362 29.9171 1.30864 29.841 1.12699C29.7649 0.945345 29.6534 0.780664 29.513 0.642547C28.9234 0.0574024 27.9657 0.0574024 27.376 0.642547L15.057 12.8943L2.62407 0.459938C2.03445 -0.131177 1.07675 -0.131177 0.487123 0.459938C-0.1025 1.05255 -0.1025 2.01174 0.487123 2.60286L12.911 15.0282L0.442217 27.4281C-0.147406 28.0132 -0.147406 28.9605 0.442217 29.5442C1.03184 30.1293 1.98954 30.1293 2.57917 29.5442L15.0374 17.1547L27.4208 29.5397C28.0105 30.1308 28.9682 30.1308 29.5578 29.5397C30.1474 28.9471 30.1474 27.9879 29.5578 27.3968L17.1834 15.0208Z"
                                    fill="#161345"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_6_9024">
                                    <rect width="30" height="30" fill="[#161345]"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </button>
                    <slot></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<style scoped lang="scss">

.modal{
    position: fixed;
    z-index: 9000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.modal-overlay{
    position: relative;
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5em 0;
}

.modal-backdoor{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    cursor: pointer;
    z-index: 10;
    transition: all 0.15s;
}

.modal-backdoor:hover{
    opacity: 0.95;
}

.modal-content{
    position: relative;
    margin-block: auto;
    margin-inline: auto;
    background-color: #E0F2F2;
    padding-inline: max(1em, 5vmin);
    padding-block: max(3em, 5vmin);
    width: 90%;
    border-radius: 2.5em;
    min-height: 16rem;
    overflow-y: auto;
    z-index: 20;
}

.modal-close{
    position: absolute;
    top: 1.5em;
    right: 1.5em;
    cursor: pointer;
    transition: all 0.15s;
}

.modal-close:hover{
    transform: scale(1.10);
}

.fade {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.3s;
    }
    &-enter,
    &-leave-to {
        opacity: 0;
    }
}

</style>
