import { createStore } from 'vuex';

const store = createStore({
    state () {
        return {
            options: undefined,
            optionsLoad: false,
            page: {},
            pageLoading: true,
            pages: {},
            //location: sessionStorage.location ?? 'Москва',
            location: sessionStorage.location,
            ip_location: sessionStorage.ip_location,
            locations: undefined,
            shops: undefined,
            modal: {
                location: undefined
            },
        }
    },
    mutations: {
        setOptions (state: any, options: object) {
            console.log( 3333 );
            state.options = options;
            state.optionsLoad = true;
        },
        setPage (state: any, page: object) {
            state.page = page;
            state.pageLoading = false;
        },
    },
    getters: {
        location (state: any, getters: object) {
            if(state.location){
                return state.location;
            }
            if(state.ip_location){
                const ip_location = JSON.parse(state.ip_location);
                if(ip_location?.locationName){
                    return ip_location.locationName;
                }
            }
            return 'Москва';
        }
    }
})

export default store;
