<script setup lang="js">
import { ref, computed, watchEffect} from "vue";
import { useStore } from "vuex";
import FirstSection from '@/components/home-page/FirstSection.vue';
import ThirdSection from '@/components/home-page/ThirdSection.vue';
import FiveSection from '@/components/home-page/FourthSection.vue';
import SeventhSection from '@/components/home-page/SeventhSection.vue';
import NewsSection from '@/components/NewsSection.vue';
import ReviewsSection from '@/components/home-page/ReviewsSection.vue';
import AboutSection from '@/components/home-page/AboutSection.vue';
import SecondSection from '@/components/home-page/SecondSection.vue';
import SixthSection from '@/components/home-page/SixthSection.vue';
import CompanySlider from '@/components/home-page/CompanySlider.vue';
import UseScrollSmoother from '@/components/home-page/UseScrollSmoother.vue';
import DogPaw from "@/components/images/DogPaw.vue";
import BritMini from "@/components/BritMini.vue";

const store = useStore();

const isLoading = ref(true);
let timeoutLoading = 0;

watchEffect(() => {
    const value = !!store.state.pageLoading;
    clearTimeout(timeoutLoading);
    timeoutLoading = setTimeout(function (){
        isLoading.value = value;
    }, 250);
});

</script>

<template>
    <template v-if="!isLoading">
        <UseScrollSmoother>
            <main class="">
                <BritMini />
                <FirstSection />
                <SecondSection />
                <ThirdSection />
                <FiveSection />
                <SixthSection />
                <CompanySlider />
                <SeventhSection />
                <NewsSection />
                <ReviewsSection />
                <AboutSection />
            </main>
        </UseScrollSmoother>
    </template>
    <template v-else>
        <div class="flex items-center justify-center min-h-[100svh]">
            <DogPaw color="#ffffff" class="z-10 pulse"/>
        </div>
    </template>
</template>

<style scoped lang="scss">
.pulse {
    animation: pulse 1s infinite;
    transform-origin: bottom right;
}
@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
</style>