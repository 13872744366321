<script setup lang="js">
import {computed, onMounted, ref, defineEmits} from "vue";
import {useStore} from "vuex";

const store = useStore();
const emit = defineEmits(['setLocation']);

const queryLocation = ref('');
//const location = computed(() => store.state.location);
const location = computed(() => store.getters.location);
const locations = computed(() => store.state.locations);

const foundLocations = computed(() => {
    const query = queryLocation.value.toLowerCase().trim();
    if(locations?.value?.length){
        return locations.value.filter(function (item){
            return item.region.toLowerCase().includes(query);
        });
    }
});

function getLocations(){
    if(locations.value === undefined) {
        fetch(`${import.meta.env.VITE_API_URL}/shops/locations`)
            .then((response) => response.json())
            .then((response) => {
                store.state.locations = response?.locations;
            });
    }
}

function setLocation(region){
    store.state.location = sessionStorage.location = region;
    emit('setLocation');
}

onMounted(function (){
    getLocations();
});

</script>

<template>

    <!--
    {{ store.state.options.location }}
    -->

    <div class="relative mt-4">
        <label class="block z-20 w-full">
            <input
                v-model="queryLocation"
                type="text"
                placeholder="Введите название региона"
                class="block w-full rounded-full py-4.5 pl-13.5 font-roboto relative z-10 outline-none border-2 border-[#BAC2CA] transition-all duration-300 focus:outline-none focus:border-blue active:border-blue text-[15px]"
            />
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                class="absolute z-20 top-1/2 -translate-y-1/2 left-4 cursor-pointer select-none"
            >
                <g clip-path="url(#clip0_1_102)">
                    <path
                        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                        stroke="#161345"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M17 17L22 22"
                        stroke="#161345"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1_102">
                        <rect width="24" height="24" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </label>
    </div>
    <div class="relative mt-4 h-64 scroll overflow-auto">
        <template v-if="locations?.length" >
            <ul v-if="foundLocations?.length">
                <li v-for="(value, index) in foundLocations" :key="index" class="mt-1">
                    <a class="cursor-pointer hover:underline" :class="{'font-semibold': location === value.region}" @click="setLocation(value.region)">{{ value.region }}</a>
                </li>
            </ul>
        </template>
    </div>

</template>

<style scoped lang="scss">
.scroll {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        background: var(--turquoise-color);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--turquoise-color-600);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #888;
    }
}
</style>